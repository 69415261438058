import React from 'react';
import { Stack } from '@tymate/margaret';
import { ArticleBody, SectionImage } from 'ui';

const Commitments = ({ data }) => (
  <Stack direction={{ default: 'column', desktop: 'row' }} gutterSize={2}>
    <div>
      <ArticleBody
        id="body"
        dangerouslySetInnerHTML={{
          __html:
            data?.datoCmsLawFirmPage?.commitmentsNode?.childMarkdownRemark
              ?.html,
        }}
      />
    </div>
    <SectionImage>
      <img
        src={data?.datoCmsLawFirmPage?.commitmentsImage?.url}
        alt={data?.datoCmsLawFirmPage?.commitmentsImage?.alt}
      />
    </SectionImage>
  </Stack>
);

export default Commitments;
