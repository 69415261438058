import React from 'react';
import styled, { css } from 'styled-components';
import { Stack, media } from '@tymate/margaret';
import { Link } from 'gatsby';
import { Label, Heading } from 'ui';

const MoreLink = styled(Link)`
  color: ${({ theme }) => theme.secondary};
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(0.25)} 0;
  text-decoration: none;
  box-shadow: inset 0 -2px 0 0 ${({ theme }) => theme.secondary};
  font-weight: 500;

  &:hover {
    color: ${({ theme }) => theme.secondaryLight};
  }
`;

const Content = styled.div`
  grid-area: content;

  p {
    line-height: 1.875;
  }
`;

const Image = styled.div`
  grid-area: image;
  max-width: 60vw;
  margin-left: auto;
  margin-right: auto;

  ${media.tablet`
    width: 600px;
    max-width: 40vw;
  `}

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-areas: 'image' 'content';
  grid-gap: ${({ theme }) => theme.spacing()};
  align-items: center;
  min-height: 40vh;

  ${media.tablet`
    grid-gap: ${({ theme }) => theme.spacing(4)};
    grid-template-areas: 'image content';
  `}

  ${({ inverted }) =>
    inverted &&
    css`
      ${media.tablet`
        grid-template-areas: 'content image';
      `}
    `}
`;

const Skills = ({ data }) => (
  <Stack direction="column" gutterSize={4}>
    {(data?.allDatoCmsSkill?.edges ?? [])
      .map(({ node }) => ({ ...node }))
      .map((node, index) => (
        <Grid inverted={index % 2} key={index}>
          <Content direction="column">
            <Label>{node.areaOfLaw?.name}</Label>
            <Heading>{node.name}</Heading>
            <p>{node?.description}</p>
            <MoreLink to={`/cabinet/${node?.slug}`}>En savoir plus</MoreLink>
          </Content>
          <Image>
            <img
              src={node.picture?.url}
              srcset={node.picture?.sizes?.srcSet}
              alt=""
            />
          </Image>
        </Grid>
      ))}
  </Stack>
);

export default Skills;
