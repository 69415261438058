import React from 'react';
import { List } from '@tymate/margaret';
import { LawyerItem } from 'components';
import LawyerCarousel from 'components/LawyerCarousel';

const Team = ({ data }) => (
  <>
    <LawyerCarousel data={data?.allDatoCmsLawyer?.edges} />

    <List direction="column" gutterSize={8} paddingTop={8} size="full">
      {(data?.allDatoCmsLawyer?.edges || []).map(({ node }, index) => (
        <LawyerItem data={node} index={index} key={index} />
      ))}
    </List>
  </>
);

export default Team;
