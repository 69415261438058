import React from 'react';
import { Stack } from '@tymate/margaret';
import { ArticleBody, SectionImage } from 'ui';

const Ethics = ({ data }) => (
  <Stack direction={{ default: 'column', desktop: 'row' }} gutterSize={2}>
    <div>
      <ArticleBody
        id="body"
        dangerouslySetInnerHTML={{
          __html:
            data?.datoCmsLawFirmPage?.ethicsNode?.childMarkdownRemark?.html,
        }}
      />
    </div>
    <SectionImage>
      <img
        src={data?.datoCmsLawFirmPage?.ethicsImage?.url}
        alt={data?.datoCmsLawFirmPage?.ethicsImage?.alt}
      />
    </SectionImage>
  </Stack>
);

export default Ethics;
