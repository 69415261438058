import React from 'react';
import { graphql } from 'gatsby';
import { Container } from '@tymate/margaret';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SEO,
  Layout,
  AppointmentSection,
} from 'components';
import { Commitments, Ethics, Team, Skills } from 'components/LawFirm';
import { PageTitle } from 'ui';

const LawFirmPage = ({ data, location }) => (
  <Layout>
    <SEO
      title={data?.datoCmsLawFirmPage?.seo?.title}
      description={data?.datoCmsLawFirmPage?.seo?.description}
    />

    <Container variant="main">
      <PageTitle>{data?.datoCmsLawFirmPage?.title}</PageTitle>
      <Tabs defaultIndex={location?.state?.defaultIndex}>
        <TabList>
          <Tab>Nos compétences</Tab>
          <Tab>L’équipe</Tab>
          <Tab>Nos engagements</Tab>
          <Tab>Nos valeurs</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Skills data={data} />
          </TabPanel>
          <TabPanel>
            <Team data={data} />
          </TabPanel>
          <TabPanel>
            <Commitments data={data} />
          </TabPanel>
          <TabPanel>
            <Ethics data={data} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
    <AppointmentSection />
  </Layout>
);

export const query = graphql`
  query {
    datoCmsLawFirmPage {
      title
      seo {
        title
        description
      }
      ethicsNode {
        childMarkdownRemark {
          html
        }
      }
      ethicsImage {
        url
        alt
      }
      commitmentsNode {
        childMarkdownRemark {
          html
        }
      }
      commitmentsImage {
        url
        alt
      }
    }
    allDatoCmsLawyer(sort: { fields: position, order: ASC }) {
      edges {
        node {
          name
          slug
          jobTitle
          photo {
            alt
            url
          }
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          linkedinLink
          quote
        }
      }
    }
    allDatoCmsSkill(sort: { fields: position, order: ASC }) {
      edges {
        node {
          slug
          name
          description
          areaOfLaw {
            name
          }
          content
          picture {
            url
            sizes {
              srcSet
            }
          }
        }
      }
    }
  }
`;

export default LawFirmPage;
